// routes
import { useLocation } from 'react-router-dom';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
// components
import KaKao from './components/settings/KaKao';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';
import LoadingScreen from './components/LoadingScreen';
// ----------------------------------------------------------------------

// 앱 및에 세팅 부분에다가 추가하면 됨 (카카오톡)
export default function App() {
  const { pathname } = useLocation();
  // console.log(pathname);
  // const isHome = pathname === '/';
  const isDashboard = pathname.includes('/dashboard');
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            {isDashboard && <Settings />}
            {!isDashboard && <KaKao />}
            {/* <KaKao /> */}
            <ScrollToTop />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
