// material
// import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 900.000000 900.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
          fill="#cd2328"
          stroke="none"
        >
          <path
            d="M7960 7050 c-19 -5 -60 -13 -90 -18 -73 -14 -140 -30 -165 -41 -11
              -5 -42 -17 -70 -26 -74 -25 -228 -101 -330 -162 -126 -76 -128 -77 -141 -88
              -6 -5 -22 -17 -34 -25 -110 -74 -314 -240 -465 -380 -165 -151 -570 -565 -700
              -715 -39 -44 -72 -82 -75 -85 -3 -3 -34 -39 -69 -80 -110 -129 -156 -183 -166
              -194 -5 -6 -28 -34 -50 -61 -21 -28 -42 -52 -45 -55 -3 -3 -30 -36 -60 -75
              -30 -38 -57 -72 -60 -75 -3 -3 -37 -45 -75 -95 -38 -49 -75 -96 -82 -103 -7
              -7 -13 -16 -13 -20 0 -4 -14 -23 -30 -42 -38 -45 -41 -48 -70 -90 -13 -19 -29
              -40 -35 -46 -5 -6 -17 -21 -25 -34 -8 -12 -49 -68 -90 -125 -41 -58 -95 -134
              -120 -170 -25 -36 -50 -71 -55 -78 -6 -7 -47 -68 -92 -137 -45 -69 -86 -129
              -91 -135 -9 -12 -101 -163 -129 -215 -20 -35 -40 -46 -58 -32 -5 4 -48 50 -95
              102 -47 52 -92 101 -100 110 -55 59 -276 296 -375 405 -66 72 -129 139 -140
              150 -11 12 -65 70 -120 130 -55 61 -129 141 -165 179 -74 78 -62 65 -195 211
              -55 60 -107 116 -115 125 -36 39 -249 269 -291 315 -91 99 -99 108 -99 115 0
              19 16 14 68 -19 31 -21 93 -61 137 -89 44 -29 87 -56 95 -62 8 -6 51 -33 95
              -62 44 -28 136 -88 205 -133 69 -45 143 -92 165 -105 22 -14 45 -28 50 -33 6
              -4 44 -30 85 -56 41 -27 129 -83 195 -126 66 -43 149 -97 185 -120 36 -23 124
              -80 195 -127 181 -118 173 -115 199 -87 12 13 52 65 91 114 38 50 72 92 75 95
              3 3 30 37 60 75 30 39 57 72 60 75 3 3 41 50 85 104 91 114 240 290 323 385
              31 35 71 80 87 100 31 37 196 220 299 331 108 116 489 494 585 581 52 46 99
              89 105 94 6 6 33 28 61 50 27 22 59 48 70 58 11 10 48 39 83 65 66 49 69 54
              50 70 -25 20 -192 102 -208 102 -9 0 -20 4 -26 8 -19 18 -143 43 -272 57 -115
              12 -153 11 -275 -1 -78 -8 -153 -19 -167 -24 -14 -5 -44 -12 -67 -15 -24 -4
              -45 -10 -48 -15 -3 -4 -20 -11 -39 -14 -20 -4 -48 -13 -63 -21 -15 -8 -32 -15
              -36 -15 -15 0 -186 -89 -226 -118 -21 -15 -49 -34 -61 -42 -174 -116 -370
              -345 -460 -537 -14 -29 -30 -53 -36 -53 -12 0 -44 32 -44 44 0 12 -99 176
              -134 221 -98 126 -186 216 -291 294 -156 117 -277 180 -440 231 -153 48 -160
              49 -310 64 -128 13 -153 13 -290 -4 -153 -18 -238 -34 -265 -49 -9 -5 -31 -12
              -50 -16 -19 -4 -41 -11 -50 -15 -8 -4 -64 -32 -125 -61 -60 -29 -112 -56 -115
              -59 -3 -3 -23 -17 -45 -30 -22 -13 -42 -26 -45 -30 -3 -3 -22 -16 -42 -30 -46
              -30 -247 -233 -274 -275 -10 -16 -21 -32 -24 -35 -38 -37 -145 -233 -179 -330
              -13 -36 -27 -74 -32 -85 -12 -27 -27 -92 -47 -200 -37 -198 -11 -587 47 -720
              5 -11 19 -48 31 -83 31 -86 90 -200 158 -302 46 -70 186 -216 772 -805 1700
              -1711 1754 -1764 1784 -1771 28 -7 64 29 844 812 1760 1768 1664 1669 1757
              1824 39 65 115 214 115 226 0 3 6 20 14 37 26 59 54 167 76 292 11 64 11 383
              0 455 -34 221 -105 406 -230 599 l-61 94 33 35 c85 90 112 113 244 212 77 58
              151 112 163 120 13 8 33 22 45 31 89 68 447 259 484 259 5 0 23 7 38 15 16 8
              37 15 47 15 9 0 25 4 35 9 31 17 156 41 212 41 67 0 101 16 49 23 -19 3 -64
              10 -100 16 -76 13 -247 13 -299 1z"
          />
        </g>
      </svg>
    </Box>
  );
}
