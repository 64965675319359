import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> }
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/analytics" replace /> },
        { path: 'analytics', element: <Analytics /> },
        {
          path: 'notice',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/notice/notice-list" replace />
            },
            // { path: 'notice-sender', element: <NoticeSender /> },
            { path: 'notice-list', element: <NoticeList /> },
            { path: 'new-post', element: <NoticeNewPost /> },
            { path: 'edit/:id', element: <NoticeNewPost /> }
          ]
        },
        {
          path: 'press',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/press/press-list" replace />
            },
            // { path: 'notice-sender', element: <NoticeSender /> },
            { path: 'press-list', element: <PressList /> },
            { path: 'new-post', element: <PressNewPost /> },
            { path: 'edit/:id', element: <PressNewPost /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      // 상단 바 로고만 나옴.
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'donor', element: <DonorFaqs /> },
        { path: 'acceptor', element: <AcceptorFaqs /> },
        { path: 'notice', element: <NoticePage /> },
        { path: 'notice/:id', element: <NoticeItem /> },
        { path: 'question', element: <Question /> },
        { path: 'press', element: <Press /> },
        { path: 'press/:id', element: <PressItem /> },
        { path: 'blog/posts', element: <Story /> },
        { path: 'activity', element: <Activity /> },
        { path: 'recruit', element: <Gathering /> },
        { path: 'support', element: <Support /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'policy', element: <Policy /> },
        { path: 'faq', element: <Faq /> },
        {
          path: 'profile',
          element: (
            <AuthGuard>
              <MyProfile />
            </AuthGuard>
          )
        }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Dashboard
const Analytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const NoticeNewPost = Loadable(lazy(() => import('../pages/dashboard/NoticeNewPost')));
const NoticeList = Loadable(lazy(() => import('../pages/dashboard/NoticeList')));
const PressList = Loadable(lazy(() => import('../pages/dashboard/PressList')));
const PressNewPost = Loadable(lazy(() => import('../pages/dashboard/PressNewPost')));

// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const MyProfile = Loadable(lazy(() => import('../pages/MyProfile')));
const Activity = Loadable(lazy(() => import('../pages/UnionActivityPage')));
const Gathering = Loadable(lazy(() => import('../pages/UnionGatheringPage')));
const Support = Loadable(lazy(() => import('../pages/UnionSupportPage')));
const NoticePage = Loadable(lazy(() => import('../pages/NoticePage')));
const NoticeItem = Loadable(lazy(() => import('../pages/NoticeItem')));
const Question = Loadable(lazy(() => import('../pages/QuestionPage')));
const Press = Loadable(lazy(() => import('../pages/PressPage')));
const PressItem = Loadable(lazy(() => import('../pages/PressItem')));
const Story = Loadable(lazy(() => import('../pages/TouchStoryPage')));
const DonorFaqs = Loadable(lazy(() => import('../pages/DonorFaqs')));
const AcceptorFaqs = Loadable(lazy(() => import('../pages/AcceptorFaqs')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
const Policy = Loadable(lazy(() => import('../pages/Policy')));
const Faq = Loadable(lazy(() => import('../pages/FaqPage')));
// const Carousel = Loadable(lazy(() => import('../pages/components-overview/Carousel')));
