// import { Icon } from '@iconify/react';
// import googleFill from '@iconify/icons-eva/google-fill';
// import twitterFill from '@iconify/icons-eva/twitter-fill';
// import facebookFill from '@iconify/icons-eva/facebook-fill';
// import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  // IconButton,
  Stack
  // Box
} from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import LogoAll from '../../components/LogoAll';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

// const SOCIALS = [
//   { name: 'FaceBook', icon: facebookFill },
//   { name: 'Google', icon: googleFill },
//   { name: 'Linkedin', icon: linkedinFill },
//   { name: 'Twitter', icon: twitterFill }
// ];

const LINKS = [
  {
    headline: '지정헌혈 요청자',
    children: [{ name: '지정헌혈 요청자', href: PATH_PAGE.acceptor }]
  },
  {
    headline: '지정헌혈 봉사자',
    children: [{ name: '지정헌혈 봉사자', href: PATH_PAGE.donor }]
  },
  {
    headline: '지정헌혈 협동조합',
    children: [
      { name: '협동조합 소개', href: PATH_PAGE.about },
      { name: '조합활동', href: PATH_PAGE.unionActivity },
      { name: '조합원 및 기부 회원 모집', href: PATH_PAGE.unionGathering }
    ]
  },
  {
    headline: '커뮤니티',
    children: [
      { name: '공지사항', href: PATH_PAGE.notice },
      { name: '문의하기', href: PATH_PAGE.question },
      { name: '감동 스토리', href: PATH_PAGE.blog.posts },
      { name: '언론보도', href: PATH_PAGE.press }
    ]
  },
  {
    headline: '이용약관',
    children: [
      { name: '사이트 이용약관', href: PATH_PAGE.policy },
      { name: '개인정보 처리방침', href: PATH_PAGE.privacy }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 5 }}>
        <LogoAll />
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          {/* <Grid item xs={12} sx={{ mb: 0 }}></Grid> */}
          <Grid item xs={8} md={3} sx={{ mt: 2 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
            <Typography variant="body2" sx={{ pr: { md: 6 }, marginLeft: 1 }}>
              지정헌혈 협동조합
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 6 }, marginLeft: 1 }}>
              대표 : 서난희
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 6 }, marginLeft: 1 }}>
              메일 : skslek0201@gmail.com
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 5 }, marginLeft: 1 }}>
              주소 : 서울시 강서구 양천로 344, B-333호
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 5 }, marginLeft: 1 }}>
              후원계좌 : 기업은행 311-087069-04-017
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 5 }, marginLeft: 1 }}>
              대표전화 : 010-7186-0201
            </Typography>

            {/* <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack> */}
          </Grid>

          <Grid item xs={12} md={9} sx={{ mt: 5 }}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 5,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2021. 지정헌혈 협동조합 All rights reserved.
        </Typography>
      </Container>
    </RootStyle>
  );
}
