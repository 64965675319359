import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: String[];
  children: ReactNode | string;
};

const useCurrentRole = () => {
  const { user } = useAuth();
  return user?.role;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>권한이 없습니다.</AlertTitle>이 페이지에 접근할 권한이 없습니다. 관리자에게
          문의하세요.
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
