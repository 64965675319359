// import { useState } from 'react';
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

// ----------------------------------------------------------------------

type AlertDialogProps = {
  open: boolean;
  setOpen: any;
  handleLogout: any;
};

export default function AlertDialog({ open, setOpen, handleLogout }: AlertDialogProps) {
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button color="info" variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}

      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle>로그아웃</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            로그아웃 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={handleLogout} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
