import { useEffect } from 'react';

// material
import { Box } from '@material-ui/core';
//

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 260;

declare global {
  interface Window {
    Kakao: any;
  }
}

const { Kakao } = window;

export default function KaKao() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    try {
      if (Kakao) {
        Kakao.init('2dc391f5b0ad615f00d746afe8bddf30');
      }
    } catch (error) {
      console.log(error);
    }
    // 채널 1:1 채팅 버튼을 생성합니다.
    Kakao.Channel.createChatButton({
      container: '#kakao-talk-channel-chat-button',
      channelPublicId: '_QBiSK',
      title: 'consult',
      size: 'small',
      color: 'yellow',
      shape: 'pc',
      supportMultipleDensities: true
    });
    document.body.appendChild(script);
    document.body.removeChild(script);
  }, []);

  return (
    <>
      <Box
        sx={{
          top: 10,
          bottom: 1,
          right: 15,
          position: 'fixed',
          zIndex: 2001
        }}
      >
        <Box
          sx={{
            px: '5px',
            mt: -3,
            left: -120,
            // top: '95%',
            bottom: '5%',
            position: 'absolute'
          }}
        >
          <div id="kakao-talk-channel-chat-button" />
        </Box>
      </Box>
    </>
  );
}
