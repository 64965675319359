// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_AUTH = '/auth';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/analytics')
    // pageTwo: path(ROOTS_DASHBOARD, '/two'),
    // pageThree: path(ROOTS_DASHBOARD, '/three')
  },
  notice: {
    root: path(ROOTS_DASHBOARD, '/notice'),
    noticeList: path(ROOTS_DASHBOARD, '/notice/notice-list'),
    edit: path(ROOTS_DASHBOARD, '/notice/edit/:id'),
    detail: path(ROOTS_DASHBOARD, '/notice/post/:id'),
    newPost: path(ROOTS_DASHBOARD, '/notice/new-post')
  },
  press: {
    root: path(ROOTS_DASHBOARD, '/press'),
    pressList: path(ROOTS_DASHBOARD, '/press/press-list'),
    edit: path(ROOTS_DASHBOARD, '/press/edit/:id'),
    detail: path(ROOTS_DASHBOARD, '/press/post/:id'),
    newPost: path(ROOTS_DASHBOARD, '/press/new-post')
  }
};

export const PATH_PAGE = {
  notice: '/notice',
  question: '/question',
  press: '/press',
  blog: {
    root: '/blog',
    posts: '/blog/posts',
    post: '/blog/post/:title',
    postById: '/blog/post/apply-these-7-secret-techniques-to-improve-event'
  },
  privacy: '/privacy',
  policy: '/policy',
  faq: 'faq',
  unionActivity: '/activity',
  unionGathering: '/recruit',
  unionSupport: '/support',
  about: '/about-us',
  contact: '/contact-us',
  donor: '/donor',
  acceptor: '/acceptor',
  page404: '/404',
  page500: '/500',
  myProfile: '/profile'
};
