/* eslint-disable import/no-duplicates */
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
// import * as dateFns from 'date-fns';

// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/authentication';
// import { User } from '../@types/user';
//
import { firebaseConfig } from '../config';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['lovelycbm@naver.com'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // firebase.firestore();
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);
// 로그인 관리
function AuthProvider({ children }: { children: ReactNode }) {
  // const [profile, setProfile] = useState<firebase.firestore.DocumentData | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);
  // const userRef = firebase.database().ref('User');

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // userRef.child('asdfasdf').once('value', (snapshot) => {
          //   console.log(snapshot.val());
          // });

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (email: string, password: string) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        // const { uid } = res.user;
        // const uid = res.user?.uid as string;
        // // userRef.child(uid).once('value', (snapshot) => {});
        // const initialData: User = {
        //   CreatedAt: dateFns.format(new Date(), 'yyyy-mm-dd hh:mm'),
        //   Push: '',
        //   PushToken: '',
        //   CollectedStamp: []
        // };
        // console.log(initialData);
        // userRef.child(uid).set(initialData);
        // 여기서 realtimedatabase로 값 입력처리 하기.
        // firebase
        //   .firestore()
        //   .collection('users')
        //   .doc(res.user?.uid)
        //   .set({
        //     uid: res.user?.uid,
        //     email,
        //     displayName: `${firstName} ${lastName}`
        //   });
      });

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const setNewPassword = async (password: string) => {
    const user = firebase.auth().currentUser;
    await user?.updatePassword(password);
  };

  const auth = { ...state.user };

  const role = ADMIN_EMAILS.includes(auth.email) ? 'admin' : 'user';
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || '',
          displayName: auth.displayName || '',
          role,
          phoneNumber: auth.phoneNumber || ''
          // country: profile?.country || '',
          // address: profile?.address || '',
          // state: profile?.state || '',
          // city: profile?.city || '',
          // zipCode: profile?.zipCode || '',
          // about: profile?.about || '',
          // isPublic: profile?.isPublic || false
        },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        setNewPassword,
        updateProfile: () => {}
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
