// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  chat: getIcon('ic_chat')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '일반',
    items: [
      { title: '통계', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard }
      // { title: 'Two', path: PATH_DASHBOARD.general.pageTwo, icon: ICONS.ecommerce },
      // { title: 'Three', path: PATH_DASHBOARD.general.pageThree, icon: ICONS.analytics }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '관리기능',
    items: [
      {
        title: '알림',
        path: PATH_DASHBOARD.notice.root,
        icon: ICONS.chat,
        children: [
          {
            title: '공지사항 목록',
            path: PATH_DASHBOARD.notice.noticeList
          },
          {
            title: '언론보도 목록',
            path: PATH_DASHBOARD.press.pressList
          }
        ]
      }
    ]
  }
];

export default sidebarConfig;
