import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import heartFill from '@iconify/icons-eva/heart-fill';
import awardFill from '@iconify/icons-eva/award-fill';
import messageFill from '@iconify/icons-eva/message-circle-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: '지정헌혈',
    path: '/',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: '지정헌혈 요청자',
    path: '/acceptor',
    icon: <Icon icon={heartFill} {...ICON_SIZE} />
  },
  {
    title: '지정헌혈 봉사자',
    path: '/donor',
    icon: <Icon icon={awardFill} {...ICON_SIZE} />
  },
  {
    title: '지정헌혈 협동조합',
    path: '/about-us',
    icon: <Icon icon={peopleFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: '협동조합',
        items: [
          { title: '협동조합 소개', path: PATH_PAGE.about },
          { title: '조합활동', path: PATH_PAGE.unionActivity },
          { title: '조합원 및 기부 후원 모집', path: PATH_PAGE.unionGathering }
          // { title: '기부 후원 모집', path: PATH_PAGE.unionSupport }
        ]
      }
    ]
  },
  {
    title: '커뮤니티',
    path: '/notice',

    icon: <Icon icon={messageFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: '커뮤니티',
        items: [
          { title: '공지사항', path: PATH_PAGE.notice },
          { title: '문의사항', path: PATH_PAGE.question },
          { title: '자주 묻는 질문', path: PATH_PAGE.faq },
          { title: '언론보도', path: PATH_PAGE.press },
          { title: '감동스토리', path: PATH_PAGE.blog.posts }
        ]
      }
    ]
  }
];

export default menuConfig;
