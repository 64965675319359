// material
import { Box, Container, Typography, Divider } from '@material-ui/core';
//
import { varFadeIn, MotionInView } from './animate';

// ----------------------------------------------------------------------

export default function LogoAll() {
  return (
    <Container maxWidth="lg">
      {/* <img src="/static/about/vision.jpg" alt="about-vision" /> */}

      <Box
        sx={{
          bottom: { xs: 24, md: 80 },
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          // position: 'absolute',
          justifyContent: 'center'
        }}
      >
        {['logo1'].map((logo) => (
          <MotionInView key={logo} variants={varFadeIn}>
            <Box
              component="img"
              src={`/static/brand/${logo}.png`}
              sx={{
                m: { xs: 1.5, md: 3 },
                height: { xs: 24, md: 30 }
              }}
            />
          </MotionInView>
        ))}
        <Divider orientation="vertical" sx={{ width: 5, minHeight: 30, mr: 2 }} />
        <Typography variant="body2">사회적 기업가 육성 사업</Typography>
      </Box>
    </Container>
  );
}
