import { useState } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Toolbar,
  Container,
  Typography,
  // IconButton,
  Divider,
  Stack,
  Link
} from '@material-ui/core';

// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import Logo from '../../components/Logo';
import AlertDialog from '../../components/AlertDialog';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
// import ProminentAppBar from './ProminentAppBar';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 100;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  },
  '@media all': {
    minHeight: 100
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { logout, isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHome = pathname === '/';

  const isMountedRef = useIsMountedRef();

  const [openLogOut, setOpenLogOut] = useState(false);

  // 홈화면이면서 오프셋이 조건에 맞을경우
  const topMenuDisplay = () => (isOffset ? true : !isHome);

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
        setOpenLogOut(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(topMenuDisplay() && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP }
          }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {topMenuDisplay() && (
          <>
            <Container maxWidth={false} sx={{ backgroundColor: '#F8F8F8' }}>
              <AlertDialog open={openLogOut} setOpen={setOpenLogOut} handleLogout={handleLogout} />
              <Container
                maxWidth="lg"
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse'
                }}
              >
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  sx={{ mb: 1, mt: 1, mr: 5 }}
                >
                  {user?.role === 'admin' && (
                    <Stack direction="row">
                      <Link
                        component={RouterLink}
                        to="/dashboard"
                        variant="subtitle2"
                        color="#999999"
                      >
                        관리자 페이지
                      </Link>
                    </Stack>
                  )}
                  <Stack direction="row">
                    <Typography variant="body2">
                      {isAuthenticated ? (
                        <Link
                          component={RouterLink}
                          to="/profile"
                          variant="subtitle2"
                          color="#999999"
                        >
                          마이페이지
                        </Link>
                      ) : (
                        <Link
                          component={RouterLink}
                          to="/auth/register"
                          variant="subtitle2"
                          color="#999999"
                        >
                          회원가입
                        </Link>
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="body2">
                      {isAuthenticated ? (
                        <Link
                          variant="subtitle2"
                          color="#999999"
                          component="span"
                          onClick={() => setOpenLogOut(true)}
                        >
                          로그아웃
                        </Link>
                      ) : (
                        <Link
                          component={RouterLink}
                          to="/auth/login"
                          variant="subtitle2"
                          color="#999999"
                        >
                          로그인
                        </Link>
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Container>
            </Container>
            <Divider sx={{ width: '100%', mb: 1 }} />
          </>
        )}
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.8
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Typography
            sx={{
              fontSize: 15,
              // 홈이 아닌곳에서는 색깔 표기 문제 없도록 처리.
              // eslint-disable-next-line no-nested-ternary
              color: isOffset ? 'common.black' : isHome ? 'common.white' : 'common.black',
              fontWeight: 'fontWeightMedium'
            }}
            component="p"
            variant="overline"
          >
            지정헌혈 협동조합
          </Typography>
          {/* <Label color="info" sx={{ ml: 1 }}>
            지정헌혈 사회적 협동조합
          </Label> */}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
